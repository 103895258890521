/* eslint-disable react-hooks/rules-of-hooks */
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import store, { AppDispatch, RootState } from "../../store/store";
import {
  clearErrorFields,
  deleteAppValue,
  getHierarchyName,
  resetAppState,
  setAppError,
  setAppValue,
  setDirtyFlag,
  updateDirtyFields,
  validationRegistry,
} from "./appStateSlice";

// Custom hook to provide state management functions
export const useAppState = () => {
  const dispatch = useDispatch<AppDispatch>();

  // Function to get the value of a specific field
  const getValue = (name: string) => {
    const state = store.getState();
    return _.get(state.appState.values, name);
  };

  // Function to get all values from the app state
  const getValues = () => {
    const state = store.getState();
    return state.appState.values;
  };

  // Function to set the value of a specific field
  const setValue = (name: string, value: any, isDisabledDirtyField?: boolean, isDisabledErrors?: boolean) => {
    dispatch(setAppValue({ name, value, isDisabledDirtyField, isDisabledErrors }));
  };
  const deleteValue = (name: string) => {
    dispatch(deleteAppValue({ name }));

    const hname = getHierarchyName(name, validationRegistry);
    if (hname) {
      _.set(validationRegistry, hname, undefined);
    }
  };

  function setAllLeafsToValue(obj: any, val: any): any {
    if (obj && typeof obj === "object") {
      const result: any = Array.isArray(obj) ? [] : {};
      for (const key in obj) {
        result[key] = setAllLeafsToValue(obj[key], val);
      }
      return result;
    } else {
      return false;
    }
  }

  const removeDirty = (name: string) => {
    const state = store.getState();
    const hierarchyName = getHierarchyName(name, state.appState.dirtyFields);
    if (hierarchyName === null) return;
    const dirtyFields = _.get(state.appState.dirtyFields, hierarchyName);

    const updatedDirtyFields = setAllLeafsToValue(dirtyFields, false);
    dispatch(updateDirtyFields({ name, value: updatedDirtyFields }));
  };

  const setDirty = (name: string, isDirty: boolean) => {
    dispatch(setDirtyFlag({ name, isDirty }));
  };

  function hasNestedTrueValue(value: any, ignoreArray?: string[], currentPath: string = ""): boolean {
    if (typeof value === "boolean") {
      return value;
    } else if (value && typeof value === "object") {
      return Object.entries(value).some(([key, val]) => {
        const newPath = currentPath ? `${currentPath}.${key}` : key;

        if (ignoreArray?.includes(key)) {
          return false;
        }

        return hasNestedTrueValue(val, ignoreArray, newPath);
      });
    } else {
      return false;
    }
  }

  const getDirty = (name: string, ignoreArray?: string[]): boolean => {
    const state = store.getState();
    const hierarchyName = getHierarchyName(name, state.appState.dirtyFields);
    if (hierarchyName === null) return false;
    const value = _.get(state.appState.dirtyFields, hierarchyName);
    return hasNestedTrueValue(value, ignoreArray);
  };

  function hasNestedNonEmptyString(value: any, ignoreArray?: string[], currentPath: string = ""): boolean | string {
    if (typeof value === "string" && value.trim() !== "") {
      return value;
    } else if (value && typeof value === "object") {
      return Object.entries(value).some(([key, val]) => {
        const newPath = currentPath ? `${currentPath}.${key}` : key;

        if (ignoreArray?.includes(key)) {
          return false;
        }

        return hasNestedNonEmptyString(val, ignoreArray, newPath);
      });
    } else {
      return false;
    }
  }

  const getError = (name: string, ignoreArray?: string[]): boolean | string => {
    const state = store.getState();
    const hierarchyName = getHierarchyName(name, state.appState.errors);
    if (hierarchyName === null) return false;
    const value = _.get(state.appState.errors, hierarchyName);
    return hasNestedNonEmptyString(value, ignoreArray);
  };

  const watchError = (name?: string, ignoreArray?: string[]) => {
    try {
      return useSelector((state: RootState) => {
        if (name) {
          const state = store.getState();
          const hierarchyName = getHierarchyName(name, state.appState.errors);
          if (hierarchyName === null) return false;
          const value = _.get(state.appState.errors, hierarchyName);
          return hasNestedNonEmptyString(value, ignoreArray);
        } else {
          return state.appState.errors;
        }
      });
    } catch {
      const state = store.getState();
      if (name) {
        const state = store.getState();
        const hierarchyName = getHierarchyName(name, state.appState.errors);
        if (hierarchyName === null) return false;
        const value = _.get(state.appState.errors, hierarchyName);
        return hasNestedNonEmptyString(value, ignoreArray);
      } else {
        return state.appState.errors;
      }
    }
  };

  // Function to watch a specific field's value dynamically or the entire state
  const watch = (name?: string) => {
    try {
      return useSelector((state: RootState) => {
        if (name) {
          // If name is provided, return the nested value
          return _.get(state.appState.values, name);
        } else {
          // If name is not provided, return the entire values object
          return state.appState.values;
        }
      });
    } catch {
      const state = store.getState();
      if (name) {
        return _.get(state.appState.values, name);
      } else {
        return state.appState.values;
      }
    }
  };

  // Function to get all errors from the app state
  const getErrors = () => {
    const state = store.getState();

    return state.appState.errors;
  };

  const converter = (name: string) => {
    const [pageId, viewName, ...rest] = name.split(".");
    return pageId + "." + viewName + "." + rest?.[rest?.length - 3] + "." + rest?.[rest?.length - 2];
  };

  // Function that takes the path of the component and runs all the validations inside it,
  // if the component is a container it will run all the children validations
  const runAllValidations = (name = "", ignoreArray?: string[]): boolean => {
    let isValid = true;
    let isIgnoreArrayEnabled = true;
    const hierarchyName = getHierarchyName(name, validationRegistry);
    if (hierarchyName === null) return true;
    const hierarchyNameKeys = hierarchyName.split(".");

    if (ignoreArray?.includes(hierarchyNameKeys[hierarchyNameKeys.length - 1])) {
      isIgnoreArrayEnabled = false;
    }
    const recurse = (currentName: string): boolean => {
      let isValid = true;
      const targetComponent = _.get(validationRegistry, currentName, null);
      const keys = currentName.split(".");
      const newHierarchyName = keys.slice(0, -1).join(".");

      if (typeof targetComponent === "function") {
        const valueToValidate = _.get(getValues(), converter(currentName));
        const result = (targetComponent as Function)(valueToValidate);

        if (result !== true && result !== undefined) {
          isValid = false;
          dispatch(setAppError({ name: newHierarchyName, error: result }));
        }
      } else if (targetComponent && typeof targetComponent === "object") {
        for (const key of Object.keys(targetComponent)) {
          if (isIgnoreArrayEnabled && ignoreArray?.includes(key)) {
            dispatch(setAppError({ name: `${currentName}.${key}`, error: "" }));
            continue;
          }
          const nextName = currentName ? `${currentName}.${key}` : key;
          const childIsValid = recurse(nextName);
          if (!childIsValid) {
            isValid = false;
          }
        }
      }

      return isValid;
    };

    isValid = recurse(hierarchyName);

    return isValid;
  };

  //Function to clear the validation on specific fields of the app hierarchy
  const clearValidation = (name: string) => {
    const state = store.getState();
    const hierarchyName = getHierarchyName(name, state.appState.errors);
    if (hierarchyName === null) return;
    const errorFields = _.get(state.appState.errors, hierarchyName);

    const clearedErrorFields = setAllLeafsToValue(errorFields, "");
    dispatch(clearErrorFields({ name, value: clearedErrorFields }));
  };

  // Function to handle form submission
  const handleSubmit = () => {
    // const isFormValid = runAllValidations("");
    // if (!isFormValid) {
    //   return { isError: true }; // Do nothing if there are errors
    // }

    // If no errors, proceed with the callback
    const values = getValues();

    return { values };
  };

  // Function to get all dirty fields from the app state
  const getDirtyFields = (name?: string) => {
    try {
      return useSelector((state: RootState) => {
        if (name) {
          // If name is provided, return the nested value
          return _.get(state.appState.dirtyFields, name);
        } else {
          // If name is not provided, return the entire dirtyFields object
          return state.appState.dirtyFields;
        }
      });
    } catch {
      const state = store.getState();
      if (name) {
        return _.get(state.appState.dirtyFields, name);
      } else {
        return state.appState.dirtyFields;
      }
    }
  };

  const getIsDirty = (name?: string) => {
    const dirtyFields = getDirtyFields(name);

    const checkNestedDirty = (fields: any): boolean => {
      if (typeof fields === "boolean") {
        return fields; // If it's a boolean, return its value directly
      }
      for (const key in fields) {
        if (typeof fields[key] === "object") {
          if (checkNestedDirty(fields[key])) {
            return true;
          }
        } else if (fields[key] === true) {
          return true;
        }
      }
      return false;
    };

    return checkNestedDirty(dirtyFields);
  };

  // Function to reset the entire app state
  const resetState = (name?: string) => {
    dispatch(resetAppState({ name }));
  };

  return {
    getValue,
    getValues,
    setValue,
    deleteValue,
    watch,
    handleSubmit,
    runAllValidations,
    getErrors,
    watchError,
    getError,
    getDirtyFields,
    getIsDirty,
    resetState,
    removeDirty,
    setDirty,
    getDirty,
    clearValidation,
  };
};
