import { CircularProgress } from "@mui/material";
import React from "react";

export const CustomSpinner: React.FC<any> = props => {
  const { spinnerSize = null } = props;
  const { heightPx, widthPx } = props?.$config || props?.config;

  const derivedSize = (heightPx?.lg + widthPx?.lg) / 2;
  const _size = spinnerSize ? spinnerSize : derivedSize;

  return (
    <div>
      <CircularProgress size={Number(_size)} />
    </div>
  );
};
